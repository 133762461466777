'use strict';
import Breakpoints from "helpers/breakpoints";
const STORAGE_KEY_SHOW_PICKER_IDS = 'show_picker_ids';
export {STORAGE_KEY_SHOW_PICKER_IDS};

export default class ShowPicker {

    constructor() {
        this.items = [];
        this.showTitles = [];
        this.positionNumbers = [];
        this.init();
    }

    __initiateVars() {

        this.b = new Breakpoints();
        this.globalContainer = $('.show-picker-container');
        this.container = this.globalContainer.find('.showpicker-scroll-container');
        this.grid = this.container.find('.grid');
        this.shows = this.grid.find('article');
        this.showPickerNextAerea = this.globalContainer.find('#show-picker--next');
        this.nextButton = this.showPickerNextAerea.find('a');
        this.skipButton = this.container.find('.skip a');
        this.navigation = $('.navigation');
        this.userMenu = $('ul.header__account > li.header__nav--withsubMenu');
        this.userMenuMobile = $('#pv-dropdown li.withSubMenu');

    }

    init() {
        this.__initiateVars();
        this.__listenEvents();
        this.__hideUserSubMenu();
    }

    __getPosterSize() {
        if (this.b.isDesktopWide()) {
            return 7;
        } else if (this.b.isDesktop()) {
            return 6;
        } else if (this.b.isTabletLandscape()) {
            return 5;
        } else if (this.b.isTabletPortrait() || this.b.isMobileLandscape()) {
            return 4;
        } else if (this.b.isMobilePortrait()) {
            return 2;
        }
    }

    __resizeHeight() {
        this.showPickerNextAerea.height(window.innerHeight - this.showPickerNextAerea.offset().top);
    }

    __hideNav() {
        this.navigation.addClass('hidden');
    }

    __handleNextButton() {
        let self = this;
        this.nextButton.click(function () {
            if (self.items.length >= 3) {
                sessionStorage.setItem(STORAGE_KEY_SHOW_PICKER_IDS, self.items.join(','));
                CBS.Promises.Tracking.onLoad(function () {
                    om.trackAction('trackShowPickerSelection', {
                        showPickerSelection: self.showTitles.join('|'),
                        showPickerPosition: self.positionNumbers.join('|'),
                    });
                })
                self.goToConfirmation();
            }
        });
    }

    __handleSkipButton() {
        this.skipButton.click(function () {
            CBS.Promises.Tracking.onLoad(function () {
                om.trackAction('trackShowPickerSkip', {});
            })
        });
    }

    __handleShowSelect() {

        let self = this;

        this.shows.find('a').click(function (event) {

            event.preventDefault();

            let showId = Number($(this).parents('.show-picker-item').attr('data-show-id'));
            let showTitle = $(this).find('img').attr('alt');

            $(this).find('.show-selector').toggleClass("selected");
            $(this).find('.overlay').toggleClass("selected").toggleClass('unselected');

            let size = self.__getPosterSize();
            let y = Math.floor(Number($(this).parent().index()) / size);
            let x = Math.floor($(this).parent().index()) - Number(y * size);

            if ($(this).find('.show-selector').hasClass('selected')) {
                self.items.push(showId);
                self.showTitles.push(showTitle);
                self.positionNumbers.push(y + ':' + x);
            } else {
                self.items = self.__sliceArray(self.items, showId);
                self.showTitles = self.__sliceArray(self.showTitles, showTitle);
                self.positionNumbers = self.__sliceArray(self.positionNumbers, y + ':' + x);
            }

            self.nextButton.attr('class', self.items.length >= 3 ? 'button' : 'button disabled');

        });

    }

    __sliceArray(data, parameter) {
        return jQuery.grep(data, function (value) {
            return value !== parameter;
        });
    }

    __listenEvents() {

        let self = this;

        $(window).resize(function () {
            self.__resizeHeight();
        });

        this.__resizeHeight();
        this.__hideNav();
        this.__handleNextButton();
        this.__handleSkipButton();
        this.__handleShowSelect();
    }

    __hideUserSubMenu() {
        if (this.userMenu) {
            this.userMenu.removeClass('header__nav--withsubMenu');
        }

        if (this.userMenuMobile) {
            this.userMenuMobile.removeClass('withSubMenu').addClass('deactivated--withSubMenu');
            let aTagFilter = this.userMenuMobile.find('> a.filter').get(0);
            let aTagFilterNew = aTagFilter.cloneNode(true);
            aTagFilter.parentNode.replaceChild(aTagFilterNew, aTagFilter);
        }
    }

    goToConfirmation() {
        window.location.href = '/all-access/subscription/payment/confirmation/';
    }

}
